import * as styles from '@/styles/Home.module.css';

import { Link, graphql, useStaticQuery } from 'gatsby'
import { useLayoutEffect, useRef, useState } from 'react';

import Contact from '@/components/Contact';
import CustomerLogos from '@/containers/CustomerLogos'
import Form from '@/components/Form';
import Helmet from 'react-helmet';
import HomeAnimation from '../components/HomeAnimation';
import HomeBanner from '../components/HomeBanner';
import Layout from '@/containers/layout';
import Metrics from '../containers/Metrics';
import Page from '@/components/Page';
import SEO from '@/components/SEO'
import { ScrollTrigger } from 'gsap/all';
import Video from '@/components/Video'
import { gsap } from 'gsap';
import { useLocation } from "@reach/router"

const IndexPage = (props) => {
  const result = useStaticQuery(graphql`
  query indexQuery {
    allMarkdownRemark(
      filter: {frontmatter: {type: {nin: ["navigation"]}}}
    ) {
      nodes {
        frontmatter {
          title
          video
          isobadge
          email
          phone
          coverphoto {
            publicURL
          }
          logo {
            publicURL
          }
          content {
            header
            text
          }
          shortDescription
          type
          value     
          model {
            publicURL
          } 
          seo {
            description
            slug
            title
            previewImage {
              publicURL
              extension
              childImageSharp {
                original {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }  
  `)


  const homeData = result.allMarkdownRemark.nodes.filter(node => node.frontmatter.title === "Home")[0];
  const generalData = result.allMarkdownRemark.nodes.filter(node => node.frontmatter.type === "general")[0];


  const contactMessage = homeData.frontmatter?.content.find(el => el.header === "Contact").text


  const coverPhoto = generalData.frontmatter?.coverphoto.publicURL;
  const homeVideo = generalData.frontmatter?.video;
  const isoBadge = generalData.frontmatter?.isobadge;
  const email = generalData.frontmatter?.email;
  const phone = generalData.frontmatter?.phone;
  let seoData = homeData.frontmatter?.seo
  seoData.canonicalURL = useLocation();

  const allMetrics = result.allMarkdownRemark.nodes.filter(node => node.frontmatter.type === "metric");
  const allServices = result.allMarkdownRemark.nodes.filter(node => node.frontmatter.type === "service");
  const allCustomers = result.allMarkdownRemark.nodes.filter(node => node.frontmatter.type === "customer");

  gsap.registerPlugin(ScrollTrigger);

  const bannerRef = useRef();
  const startRef = useRef();
  const serviceRef = useRef();
  const [activeSlide, setActiveSlide] = useState(0);

  useLayoutEffect(() => {
    const q = gsap.utils.selector(bannerRef);
    const bannerStickers = q('li');

    ScrollTrigger.create({
      trigger: startRef.current,
      start: 'top top',
      end: `+=${(bannerStickers.length + 1) * 500}px`,
      pin: true,
    })

    bannerStickers.forEach((el, i) => {
      ScrollTrigger.create({
        trigger: el,
        start: `${550 * i}`,
        end: `${(500 + 550 * i)}`,
        scrub: true,
        onEnter: () => {
          el.classList.add('banner-active')
          setActiveSlide(i)
        },
        onEnterBack: () => {
          el.classList.add('banner-active')
          setActiveSlide(i)
        },
        onLeave: () => {
          if (i < bannerStickers.length - 1) {
            el.classList.remove('banner-active')
            setActiveSlide(i)
          }
        },
        onLeaveBack: () => {
          el.classList.remove('banner-active')
          setActiveSlide(i)
        },
      })
    })

    ScrollTrigger.refresh();

    return () => {
      ScrollTrigger.getAll().forEach(st => st.kill())
    }
  }, [])

  return (<>
    <Helmet>
      <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
    </Helmet>
    <SEO data={seoData}></SEO>
    <Layout white>
      <div className={styles.landing}>
        <div className={styles.background}>
          <Video src={homeVideo} coverphoto={coverPhoto} />
        </div>
        <div className={styles.landingContent}>
          <div className={'spacer'}></div>
          <h1>
            {homeData?.frontmatter.content[0].header}
          </h1>
          <Link to="/request-a-quote"><button>REQUEST A QUOTE</button></Link>
        </div>
        <div className={styles.landingbadge}>
          <h2>{isoBadge}</h2>
        </div>
      </div>
      <div className={styles.services} ref={startRef}>
        <div ref={bannerRef}>
          <HomeBanner data={allServices} />
        </div>
        <div className={styles.serviceAnimation} ref={serviceRef}>
          <HomeAnimation data={allServices} activeSlide={activeSlide} />
        </div>
      </div>
      <Page relative>
        <h1 className={styles.profile}>Company Profile</h1>
        <Metrics data={allMetrics} />
      </Page>
      <Page color={'darkred'} paddingtop center >
        <div className={styles.companies}>
          <div className={styles.companiesText}>
            <h1>
              S & C Plastic is Trusted
              <br />
              by Industry Leaders You Know
            </h1>
            <p>
              {homeData?.frontmatter.content[1].text}
            </p>
          </div>
          <div className={styles.companiesLogos}>
            <CustomerLogos data={allCustomers} />
          </div>

        </div>
      </Page>
      <Page>
        <div className={styles.contact}>
          <div>
            <Contact text={contactMessage} email={email} phone={phone} />
          </div>
          <div>
            <Form></Form>
          </div>
        </div>
      </Page>
    </Layout>
  </>);
};
export default IndexPage;

