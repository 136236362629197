import * as styles from './Video.module.css'

import { useLayoutEffect, useState } from 'react';

import ReactPlayer from 'react-player';
import useWindowDimensions from "@/lib/getScreenSize";

const Video = props => {

    const { width, height } = useWindowDimensions()
    const [videoWidth, setVideoWidth] = useState();
    const [videoHeight, setVideoHeight] = useState();

    const [playing, setPlaying] = useState(false);

    const handlePlaying = (e) => {
        e.preventDefault()
        setPlaying(true)

    }

    const setVideoDimensions = () => {
        const innerVideoHeight = width * 9 / 16;
        let newWidth, newHeight;
        if ((width / height) <= 16 / 9) {
            newWidth = width / innerVideoHeight * height;
            newHeight = height;
        } else if ((width / height) > 16 / 9) {
            newWidth = width;
            newHeight = width * (9 / 16);
        }

        setVideoWidth(newWidth);
        setVideoHeight(newHeight);
    }
    useLayoutEffect(() => {
        setVideoDimensions();
    }, [width, height])


    return (
        <div >
            <div className={[styles.Placeholder, playing && styles.Hidden].join(" ")}>
                <img src={props.coverphoto} alt="" />
            </div>
            <div className={styles.VideoPlayer}>
                <ReactPlayer width={videoWidth} height={videoHeight} loop={true} playsinline={true} playing={true} muted={true} volume={1} controls={false} url={props.src}
                    onStart={() => setPlaying(true)}
                />
            </div>
            {/* "https://www.youtube.com/watch?v=wh3ZTxQhoiU" */}
        </div>
    )

}

export default Video;