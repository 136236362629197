import * as styles from './Metrics.module.css';

const Metrics = ({ data }) => {
    return (
        <div className={styles.badgegrid}>
            {data.map((el, i) => {
                return (
                    <div key={i} className={`${styles.badge}`}>
                        <p>
                            <span className={styles.bigletters}>{el.frontmatter?.value}</span>
                            <br />
                            <span>{el.frontmatter?.title}</span>
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

export default Metrics;
