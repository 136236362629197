import * as styles from './CustomerLogos.module.css'

import Grid from '@/components/Grid'
import Icon from '@/images/google.png'

const CustomerLogos = ({ data }) => {
    return (
        <div className={styles.companiesLogos}>
            <Grid>
                {data.map(el => {
                    return (
                        <img src={el.frontmatter?.logo?.publicURL} alt={el.frontmatter?.title} key={el.frontmatter?.title} className={styles.companyLogo} />
                    )
                })}
            </Grid>
        </div>
    )

}


export default CustomerLogos