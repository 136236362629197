// extracted by mini-css-extract-plugin
export var background = "Home-module--background--JIhtS";
export var centertitle = "Home-module--centertitle--smrzA";
export var companies = "Home-module--companies--QOmdM";
export var companiesLogos = "Home-module--companiesLogos--D5AmZ";
export var companiesText = "Home-module--companiesText--u-lVw";
export var contact = "Home-module--contact--inbTe";
export var contactmessage = "Home-module--contactmessage--sfLso";
export var contacttext = "Home-module--contacttext--uogp4";
export var heading = "Home-module--heading--O+WHM";
export var home = "Home-module--home--7rEnb";
export var image = "Home-module--image--8Evu6";
export var intro = "Home-module--intro--tEgVK";
export var landing = "Home-module--landing--FGSuc";
export var landingContent = "Home-module--landingContent--Z-L04";
export var landingbadge = "Home-module--landingbadge--klvVt";
export var logosHeader = "Home-module--logosHeader--9yP2K";
export var profile = "Home-module--profile--u9CoU";
export var quote = "Home-module--quote--eF7w2";
export var serviceAnimation = "Home-module--serviceAnimation--aBhTU";
export var services = "Home-module--services--uxNsq";