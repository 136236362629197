import * as styles from './Contact.module.css';

const Contact = (props) => {
  return (
    <div className={styles.Contact}>
      <h1>Contact</h1>
      <p>{props.text}</p>
      <div className={styles.address}>
        <p className={styles.heading}>Address:</p>
        <p>2701 W River Dr NW<br /> Grand Rapids, MI, 49544</p>
        <p className={styles.heading}>Phone:</p>
        <p><a href={`tel:${props.phone}`}>{props.phone}</a></p>
        <p className={styles.heading}>Email:</p>
        <p><a href={`mailto:${props.email}`}>{props.email}</a></p>
      </div>
    </div>
  );
}


export default Contact;