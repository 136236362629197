import * as styles from './HomeAnimation.module.css'

import { useEffect, useRef } from 'react';

const HomeAnimation = props => {

    const active = props.activeSlide

    const data = props.data;

    const videoRef = useRef();

    const setPlaybackRate = () => videoRef.current.playbackRate = 0.25;

    // useEffect(() => {
    //     if (typeof window !== undefined && videoRef !== undefined) {
    //         const targets = document.querySelectorAll('#animation');
    //         targets.forEach(el => {
    //             el.playbackRate = 0.65;

    //             el.
    //         })
    //     }
    // }, [videoRef])

    return (
        <div className={styles.container} ref={videoRef}>
            {data.map((el, i) => {
                return (
                    <div key={el.frontmatter.title} className={[styles.slide, active === i ? styles.active : null].join(" ")} id={i} >
                        <video id={'animation'} onLoadedMetadata={() =>
                            setPlaybackRate(0.25)
                        } className={styles.video} autoPlay muted loop ref={videoRef} playsInline >
                            <source src={el.frontmatter?.model?.publicURL} type="video/mp4" />
                        </video>
                    </div>)
            })}
        </div >)

}

export default HomeAnimation;