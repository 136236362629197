import * as styles from "./HomeBanner.module.css";

import { Link } from 'gatsby'

const HomeBanner = ({ data }) => {


    return (
        <div className={styles.serviceBanner}>
            <h1 className={styles.heading}>
                Our Services
            </h1>
            <ul className={styles.serviceList} id='service-card'>
                {data.map((el, i) => (

                    <li id='service-card' className={styles.serviceCard} key={el.frontmatter?.title}>
                        <div className={styles.listNumber}>{i + 1}</div>
                        <Link to={`services/${el.frontmatter?.seo?.slug}`} className={styles.serviceText}>
                            <h2>
                                {el.frontmatter?.title}</h2>
                            <p>{el.frontmatter?.shortDescription}</p>
                        </Link>
                    </li>

                ))}
            </ul>
        </div>
    )
}

export default HomeBanner;